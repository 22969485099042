import {Observable} from 'rxjs';
import {AdsModel} from '../model/ads.model';
import Api from '../../../../../../../core/services/Api';
import {GET_ADS_URL} from '../CONSTANTS';

export class AdsService {
  public getAds(id: number): Observable<AdsModel> {
    return Api.get(GET_ADS_URL + id + '/ad/');
  }
}
