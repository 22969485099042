import React, {FC} from 'react';
import {WidgetProps, WidgetTitleBar} from './component/widgetTitleBar';
import SquareShortcutComponent from '../shortcut/squareShortcut.component';
import {ColumnComponent} from '../../../../../core/components/base/column.component';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {FreeMode} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {WidgetContainerComponent} from '../../../../../core/components/ui/widgetContainer.component';
import Image from 'next/image';

export const ShortcutSquareSwiperComponent: FC<WidgetProps> = (props) => {
  return (
    <WidgetContainerComponent className="fullWidth">
      <ColumnComponent>
        <WidgetTitleBar widget={props.widget} hasTitle={props.hasTitle} />
        <RowComponent
          className={`${props.widget.schema_data?.background_color ? 'py-2' : ''} relative totalCenter`}
          style={{ backgroundColor: props.widget.schema_data?.background_color ?? 'transparent' }}
        >
          {props.widget.schema_data?.side_image && props.widget.schema_data?.side_image_behavior === 'fading' ? (
            <Image
              alt={''}
              fill={true}
              src={props.widget.schema_data.side_image}
              sizes={'100%'}
              style={{ objectFit: 'fill' }}
              className={'swiperRecyclerFirstImage right-0'}
            />
          ) : null}
          <RowComponent className="flex-nowrap overflow-scroll hideScrollBar">
            <Swiper
              slidesPerView={'auto'}
              freeMode={true}
              modules={[FreeMode]}
              style={{
                paddingRight:
                  props.widget.schema_data.side_image && props.widget.schema_data?.side_image_behavior === 'fading'
                    ? '10rem'
                    : ''
              }}
              className="mySwiper"
            >
              {props.widget.schema_data.side_image && props.widget.schema_data?.side_image_behavior === 'scrolling' ? (
                <SwiperSlide style={{ width: 'auto' }} key={0} virtualIndex={0}>
                  <Image
                    fill={true}
                    placeholder={'empty'}
                    alt={''}
                    src={props.widget.schema_data.side_image ?? ''}
                    style={{ objectFit: 'fill' }}
                    className={`swiperRecyclerFirstImage !relative`}
                  />
                </SwiperSlide>
              ) : null}
              {props.widget.shortcuts?.map((item, index) => (
                <SwiperSlide style={{ width: 'auto' }} key={index + 1} virtualIndex={index + 1}>
                  {index < (props.widget.schema_data.unit_max_size ?? 100) * 4 ? (
                    <div key={index} className="p-2 width-auto">
                      <SquareShortcutComponent
                        shortcut={item}
                        textColor={props.widget.schema_data?.text_color ?? null}
                      />
                    </div>
                  ) : null}
                </SwiperSlide>
              ))}
            </Swiper>
          </RowComponent>
        </RowComponent>
      </ColumnComponent>
    </WidgetContainerComponent>
  );
};

export default React.memo(ShortcutSquareSwiperComponent);
