import React, {FC} from 'react';
import {MenuBuilderShortcutModel} from '../../../../Main/main.model';
import {useTranslation} from 'react-i18next';
import {LOGIN_STATE_KEY, PERSIAN} from '../../../../../core/services/CONSTANTS';
import {ADS_SWIPER_BREAKPOINTS, BANNER_LARGE_SIZE, BANNER_MEDIUM_SIZE, BANNER_SMALL_SIZE} from '../CONSTANTS';
import {A11y, Navigation, Pagination, Scrollbar} from 'swiper';
import {onShortcutClick} from '../shortcut/service/shortcut.service';
import {WidgetProps, WidgetTitleBar} from './component/widgetTitleBar';
import {LOGIN_ACTION} from '../../../../profile/components/Login/redux/loginAction';
import {LOGIN_STATE} from '../../../../profile/components/Login/CONSTANTS';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../../../core/redux/store';

import i18n from 'i18next';
import {ColumnComponent} from '../../../../../core/components/base/column.component';
import {useRouter} from 'next/router';
import {Swiper, SwiperSlide} from 'swiper/react';
import useLocalStorageState from '../../../../../core/utils/useLocalStorage';
import {WidgetContainerComponent} from '../../../../../core/components/ui/widgetContainer.component';
import Image from 'next/image';

const BannerSliderWidgetComponent: FC<WidgetProps> = (props) => {
  const router = useRouter();
  const { t } = useTranslation();

  function getHeight() {
    switch (props.widget.schema_data.size) {
      case BANNER_LARGE_SIZE:
        return 'aspect-[32/17]';
      case BANNER_MEDIUM_SIZE:
        return 'aspect-[32/10]';
      case BANNER_SMALL_SIZE:
        return 'aspect-[32/5]';
      default:
        return 'aspect-[32/10]';
    }
  }

  let dispatch = useDispatch<AppDispatch>();
  const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);

  function getShortcutTitle(shortcut: MenuBuilderShortcutModel) {
    if (shortcut.title) {
      return i18n.language == PERSIAN ? shortcut.title.fa : shortcut.title.en;
    } else {
      return t(`shName.${shortcut.unique_name}`);
    }
  }

  function handleShortcutClick(shortcut: MenuBuilderShortcutModel) {
    if (shortcut?.is_enabled ?? true) {
      if ((shortcut.login_required ?? false) && !isLogin) {
        dispatch({
          type: LOGIN_ACTION,
          openLoginPage: true,
          state: LOGIN_STATE
        });
      } else {
        if (shortcut.url) {
          let title = getShortcutTitle(shortcut);
          onShortcutClick(shortcut, title, router, dispatch);
        }
      }
    }
  }

  return (
    <WidgetContainerComponent className="fullWidth flex flex-col">
      <WidgetTitleBar widget={props.widget} hasTitle={props.hasTitle} />
      <div className="w-full">
        <Swiper
          effect="slide"
          slidesPerView={1}
          spaceBetween={10}
          centeredSlides={true}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          loop={props.widget.shortcuts.length > 1}
          autoplay={{
            delay: props.widget.shortcuts.length > 1 ? 3500 : 100000,
            disableOnInteraction: false
          }}
          pagination={true}
          breakpoints={ADS_SWIPER_BREAKPOINTS}
        >
          {props.widget.shortcuts?.map((item, index) => (
            <SwiperSlide key={index} tabIndex={index} virtualIndex={index}>
              <ColumnComponent className={'p-1 totalCenter mt-2'}>
                <div className="flex w-full totalCenter px-3">
                  <Image
                    fill={true}
                    sizes={'100%'}
                    alt=""
                    className={`mRounded-2 ratio !relative ${getHeight()}`}
                    src={item.cover_url?.toString() ?? ''}
                    style={{ opacity: !(item.is_enabled && !item.is_enabled) ? '1' : '0.5' }}
                    onClick={() => handleShortcutClick(item)}
                  />
                </div>
              </ColumnComponent>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </WidgetContainerComponent>
  );
};

export default React.memo(BannerSliderWidgetComponent);
