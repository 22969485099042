import React, {FC} from 'react';
import {MenuBuilderShortcutModel} from '../../../../Main/main.model';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {LOGIN_STATE_KEY, PERSIAN} from '../../../../../core/services/CONSTANTS';
import {useTranslation} from 'react-i18next';
import {onShortcutClick} from './service/shortcut.service';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import {LOGIN_ACTION} from '../../../../profile/components/Login/redux/loginAction';
import {LOGIN_STATE} from '../../../../profile/components/Login/CONSTANTS';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../../../core/redux/store';

import i18n from 'i18next';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {useRouter} from 'next/router';
import {Avatar} from '@mui/material';
import useLocalStorageState from '../../../../../core/utils/useLocalStorage';

interface ShortcutProps {
  shortcut: MenuBuilderShortcutModel;
}

export const ShortcutLinearComponent: FC<ShortcutProps> = (props) => {
  const router = useRouter();
  const { t } = useTranslation();

  let dispatch = useDispatch<AppDispatch>();
  const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);

  function getShortcutTitle() {
    if (props.shortcut.title) {
      return i18n.language == PERSIAN ? props.shortcut.title.fa : props.shortcut.title.en;
    } else {
      return t(`shName.${props.shortcut.unique_name}`);
    }
  }

  function handleShortcutClick() {
    if (props.shortcut?.is_enabled ?? true) {
      if ((props.shortcut.login_required ?? false) && !isLogin) {
        dispatch({
          type: LOGIN_ACTION,
          openLoginPage: true,
          state: LOGIN_STATE
        });
      } else {
        let title = getShortcutTitle();
        onShortcutClick(props.shortcut, title, router, dispatch);
      }
    }
  }

  function getIconColor(icon_color: string) {
    let color = icon_color.replace('#', '');
    let a = color.substr(0, 2);
    color = color.substring(2, 8) + a;
    return '#' + color;
  }

  return (
    <div className="w-full pointer">
      {props.shortcut ? (
        <RowComponent
          onClick={() => handleShortcutClick()}
          style={{ opacity: !(props.shortcut.is_enabled && !props.shortcut.is_enabled) ? '1' : '0.5' }}
          className="p-1 totalCenter"
        >
          {props.shortcut.icon_url?.includes('http') ? (
            <Avatar
              variant={'rounded'}
              alt=""
              className="m-2 h-full mRounded-2"
              style={{ backgroundColor: getIconColor(props.shortcut.icon_color), width: '3rem', height: '3rem' }}
              src={props.shortcut.icon_url}
            >
              <ImageOutlinedIcon className="aspect-[1/1] w-full h-full p-2 opacity-50" />
            </Avatar>
          ) : (
            <Avatar
              variant={'rounded'}
              alt=""
              style={{ backgroundColor: getIconColor(props.shortcut.icon_color), width: '3rem', height: '3rem' }}
              className="m-2 flex flex-col h-full mRounded-2"
              src={`/image/png_ic_${props.shortcut.unique_name}.png`}
            >
              <ImageOutlinedIcon className="aspect-[1/1] w-full h-full p-2 opacity-50" />
            </Avatar>
          )}
          {props.shortcut.title ? (
            <div className="grow text-start" style={{ fontSize: '.75rem' }}>
              {i18n.language == PERSIAN ? props.shortcut.title.fa : props.shortcut.title.en}
            </div>
          ) : (
            <div className="grow text-start" style={{ fontSize: '.75rem' }}>
              {t(`shName.${props.shortcut.unique_name}`)}
            </div>
          )}

          <ArrowBackIosIcon className="m-1 arrowCircleBackground" />
        </RowComponent>
      ) : null}
    </div>
  );
};
