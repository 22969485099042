import React, {FC} from 'react';
import {MenuBuilderWidgetModel} from '../../../../Main/main.model';
import BannerSwiperShortcutComponent from '../shortcut/bannerSwiperShortcut.component';
import {WidgetTitleBar} from './component/widgetTitleBar';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode} from 'swiper';
import {WidgetContainerComponent} from '../../../../../core/components/ui/widgetContainer.component';

interface WidgetProps {
  widget: MenuBuilderWidgetModel;
  hasTitle: boolean;
}

const BannerSwiperWidgetComponent: FC<WidgetProps> = (props) => {
  return (
    <WidgetContainerComponent className="fullWidth flex flex-col">
      <WidgetTitleBar widget={props.widget} hasTitle={props.hasTitle} />
      <RowComponent className="w-full flex flex-row flex-nowrap overflow-scroll hideScrollBar">
        <Swiper slidesPerView={'auto'} freeMode={true} modules={[FreeMode]} className="mySwiper !px-2">
          {props.widget.shortcuts?.map((item, index) => (
            <SwiperSlide style={{ width: 'auto' }} key={index} virtualIndex={index}>
              <div className="w-auto p-2 width-auto" key={index}>
                <BannerSwiperShortcutComponent shortcut={item} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </RowComponent>
    </WidgetContainerComponent>
  );
};

export default React.memo(BannerSwiperWidgetComponent);
