import React, {FC, useEffect, useState} from 'react';
import {AdsModel} from '../../../../../Main/main.model';
import {ADS_BANNER_TYPE, ADS_LARGE_SIZE, ADS_MEDIUM_SIZE, ADS_NATIVE_TYPE, ADS_SMALL_SIZE} from '../../CONSTANTS';
import {WidgetProps, WidgetTitleBar} from '../component/widgetTitleBar';
import {ColumnComponent} from '../../../../../../core/components/base/column.component';
import {onShortcutClickFromUrl} from '../../shortcut/service/shortcut.service';
import {AdsService} from './service/ads.service';
import {AdsModelData} from './model/ads.model';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../../../../core/redux/store';
import {useRouter} from 'next/router';

const AdsWidgetComponent: FC<WidgetProps> = (props) => {
  const router = useRouter();
  let dispatch = useDispatch<AppDispatch>();

  const [adsModel, setAdsModel] = useState<AdsModelData>();
  const [normalAds, setNormalAds] = useState(true);

  useEffect(() => {
    const adsService = new AdsService();
    adsService.getAds(props.widget.id).subscribe((data) => {
      if (data && data.data) {
        setAdsModel(data.data);
      }
    });
  }, []);

  function getHeight() {
    switch (props.widget.schema_data.ad_size) {
      case ADS_LARGE_SIZE:
        return 'aspect-[32/17]';
      case ADS_MEDIUM_SIZE:
        return 'aspect-[32/10]';
      case ADS_SMALL_SIZE:
        return 'aspect-[32/5]';
      default:
        return 'aspect-[32/10]';
    }
  }

  function handleBannerClick(ads: AdsModel) {
    onShortcutClickFromUrl(ads.action ?? '', props.widget.title, router, dispatch);
  }

  function getAdsView(ads: AdsModel, normalAds: boolean) {
    switch (ads.ad_type) {
      case ADS_NATIVE_TYPE:
        return (
          <div className={`flex w-full totalCenter pointer`}>
            <div
              className={`rounded-md w-full ${getHeight()}`}
              dangerouslySetInnerHTML={{ __html: ads.content.replace('<style>*', '<style>') }}
            />
          </div>
        );
      case ADS_BANNER_TYPE:
        return (
          <div className={`flex w-full totalCenter pointer`}>
            <img
              alt=""
              className={`rounded-md w-full ${getHeight()}`}
              src={ads.content}
              onError={() => (normalAds ? setNormalAds(false) : null)}
              onClick={() => handleBannerClick(ads)}
            />
          </div>
        );
      default:
        return <div />;
    }
  }

  function getAdsTypeAndModel(adsModel: AdsModelData | undefined) {
    if (adsModel) {
      if (normalAds) {
        if (adsModel.ad && (adsModel.ad.ad_type == ADS_BANNER_TYPE || adsModel.ad.ad_type == ADS_NATIVE_TYPE)) {
          return getAdsView(adsModel.ad, true);
        }
      } else {
        if (
          adsModel.alternative_ad &&
          (adsModel.alternative_ad.ad_type == ADS_BANNER_TYPE || adsModel.alternative_ad.ad_type == ADS_NATIVE_TYPE)
        ) {
          return getAdsView(adsModel.alternative_ad, false);
        }
      }
    }
  }

  return (
    <ColumnComponent className="fullWidth">
      <WidgetTitleBar widget={props.widget} hasTitle={props.hasTitle} />
      <ColumnComponent className="totalCenter">
        <div className={'w-full md:w-2/3 lg:w-1/2 px-5 mt-2 mb-2 totalCenter'}>{getAdsTypeAndModel(adsModel)}</div>
      </ColumnComponent>
    </ColumnComponent>
  );
};

export default React.memo(AdsWidgetComponent);
