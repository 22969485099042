import React, {FC} from 'react';
import {WidgetProps, WidgetTitleBar} from './component/widgetTitleBar';
import {ColumnComponent} from '../../../../../core/components/base/column.component';
import {RowComponent} from '../../../../../core/components/base/row.component';
import ShortcutComponent from '../shortcut/shortcut.component';
import {FreeMode} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {WidgetContainerComponent} from '../../../../../core/components/ui/widgetContainer.component';

export const ShortcutSwiperComponent: FC<WidgetProps> = (props) => {
  return (
    <WidgetContainerComponent className="fullWidth">
      <ColumnComponent>
        <WidgetTitleBar widget={props.widget} hasTitle={props.hasTitle} />
        <RowComponent className="flex-nowrap overflow-scroll hideScrollBar px-2">
          <Swiper slidesPerView={'auto'} freeMode={true} modules={[FreeMode]} className="mySwiper">
            {props.widget.shortcuts?.map((item, index) => (
              <SwiperSlide style={{ width: 'auto' }} key={index} virtualIndex={index}>
                {index < (props.widget.schema_data.unit_max_size ?? 100) * 4 ? (
                  <div key={index} className="p-2 width-auto">
                    <ShortcutComponent shortcut={item} />
                  </div>
                ) : null}
              </SwiperSlide>
            ))}
          </Swiper>
        </RowComponent>
      </ColumnComponent>
    </WidgetContainerComponent>
  );
};

export default React.memo(ShortcutSwiperComponent);
