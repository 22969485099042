import React, { FC } from 'react';
import { MenuBuilderWidgetModel } from '../../../../../Main/main.model';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../../core/redux/store';
import { onShowMoreClick } from '../../shortcut/service/shortcut.service';
import { ArrowIconComponent } from '../../../../../../core/components/arrowIcon.component';
import { RowComponent } from '../../../../../../core/components/base/row.component';
import { ColumnComponent } from '../../../../../../core/components/base/column.component';
import { useRouter } from 'next/router';

export interface WidgetProps {
  widget: MenuBuilderWidgetModel;
  hasTitle: boolean;
}

export const WidgetTitleBar: FC<WidgetProps> = (props) => {
  let dispatch = useDispatch<AppDispatch>();
  const router = useRouter();

  function onClickAll() {
    return () => {
      onShowMoreClick(props.widget, dispatch, router);
    };
  }

  return (
    <ColumnComponent style={{ backgroundColor: props.widget.schema_data?.background_color ?? 'transparent' }}>
      {props.hasTitle ? (
        props.widget.schema_data?.show_title === false ? null : (
          <RowComponent dir="auto" className="totalCenter px-2">
            <div
              className="text-base text-bold m-2 grow mText"
              style={{ color: props.widget.schema_data?.text_color ?? 'black' }}
            >
              {props.widget.title}
            </div>
            <ArrowIconComponent
              isDisplay={props.widget?.schema_data?.show_more_enabled ?? false}
              color={props.widget.schema_data?.text_color ?? 'var(--theme-color-blue)'}
              onClick={onClickAll()}
            />
          </RowComponent>
        )
      ) : null}
    </ColumnComponent>
  );
};
