import React, {FC} from 'react';
import {VideoListModel} from '../../../../tools/video/model/video.model';
import {toPersianNumber} from '../../../../../core/utils/number.utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {BOARD_FILM_URL, FILM_SERIAL_PATH, VIDEO_GARDI_PATH} from '../../../../tools/video/CONSTANTS';
import {ColumnComponent} from '../../../../../core/components/base/column.component';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {useRouter} from 'next/router';

interface ShortcutProps {
  video: VideoListModel;
  sourceUrl: string | null;
}

const VideoSwiperShortcutComponent: FC<ShortcutProps> = (props) => {
  const router = useRouter();

  function videoClick(token: string) {
    router.push({
      pathname: props.sourceUrl?.includes(BOARD_FILM_URL)
        ? `${FILM_SERIAL_PATH}/${token}`
        : `${VIDEO_GARDI_PATH}/${token}`
    });
  }

  return (
    <div className="w-full">
      {props.video ? (
        <ColumnComponent className="p-1 pointer" onClick={() => videoClick(String(props.video.friendly_token))}>
          <div className="aspect-[16/9] overflow-hidden relative mRounded-1 card card-box videoBannerIconImage">
            <img alt="" src={props.video.thumbnail_url} className="videoImageBlur" />
            <img alt="" src={props.video.thumbnail_url} className="videoImage" />
          </div>
          <RowComponent className="text-wrap p-1">
            <div className="fs-7 p-1 grow text-start text-wrap" style={{ maxWidth: '10rem' }}>
              {props.video.title}
            </div>
            <div className="flex flex-row w-auto justify-end">
              <div className="fs-7 text-end p-1">{toPersianNumber(props.video.views.toString())}</div>
              <VisibilityIcon className={'mt-1'} fontSize={'small'} />
            </div>
          </RowComponent>
        </ColumnComponent>
      ) : null}
    </div>
  );
};

export default React.memo(VideoSwiperShortcutComponent)
