import React, { FC } from 'react';
import { ShortcutLinearComponent } from '../shortcut/shortcutLinear.component';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../core/redux/store';
import { onShowMoreClick } from '../shortcut/service/shortcut.service';
import { WidgetProps } from './component/widgetTitleBar';
import { ColumnComponent } from '../../../../../core/components/base/column.component';
import { useRouter } from 'next/router';
import { WidgetContainerComponent } from '../../../../../core/components/ui/widgetContainer.component';

const VerticalWidgetComponent: FC<WidgetProps> = (props) => {
  const { t } = useTranslation();
  let dispatch = useDispatch<AppDispatch>();
  const router = useRouter();

  function onClickAll() {
    return () => {
      onShowMoreClick(props.widget, dispatch, router);
    };
  }

  return (
    <WidgetContainerComponent className="responsiveWidth flex flex-col p-3">
      <div className="mb-n2">
        {props.widget.schema_data?.show_title == false ? null : (
          <ColumnComponent className="totalCenter">
            <div className="text-bold text-base cardTitle">{props.widget.title}</div>
          </ColumnComponent>
        )}
      </div>
      <div className="topNegativeMargin">
        <ColumnComponent className="card card-box p-1">
          {props.widget.shortcuts?.map((item, index) =>
            index < 4 ? (
              <div key={index} className="w-full">
                <ShortcutLinearComponent shortcut={item} />
              </div>
            ) : null
          )}
        </ColumnComponent>
      </div>
      <div className="w-full flex totalCenter topNegativeMargin1">
        <div onClick={onClickAll()} className="seeAllButton text-center text-base">
          {t('seeAll')}
        </div>
      </div>
    </WidgetContainerComponent>
  );
};

export default React.memo(VerticalWidgetComponent);
