import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {ColumnComponent} from '../../../../../core/components/base/column.component';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import {FAVORITE} from '../../../../../core/navigation/CONSTANTS';
import {AppDispatch} from '../../../../../core/redux/store';
import {SHORTCUT_ACTION} from '../widget/favorite/redux/shortcutAction';
import {useDispatch} from 'react-redux';
import {useRouter} from 'next/router';
import {Avatar} from '@mui/material';

export const ShortcutShowMoreComponent: FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  let dispatch = useDispatch<AppDispatch>();

  function onClickAll() {
    return () => {
      dispatch({
        type: SHORTCUT_ACTION,
        item: null,
        hasSetting: false
      });
      router.push({
        pathname: FAVORITE,
        search: `shTitle=${t('favorite')}`
      });
    };
  }

  return (
    <div className="w-full">
      <ColumnComponent className="totalCenter pointer" onClick={onClickAll()}>
        <ColumnComponent className="totalCenter px-2">
          <Avatar
            variant={'rounded'}
            alt=""
            style={{ backgroundColor: 'var(--theme-page-background-dark)' }}
            className="aspect-[1/1] shortcutIconImage flex flex-col  mRounded-2"
            src={`/image/svg_ic_more.svg`}
          >
            <ImageOutlinedIcon className="aspect-[1/1] w-full h-full p-2 opacity-50" />
          </Avatar>
        </ColumnComponent>
        <div className="mText text-center text-xs max-h-4 mt-1 w-full truncate">{t('more')}</div>
      </ColumnComponent>
    </div>
  );
};
