import React, {FC} from 'react';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {WidgetProps, WidgetTitleBar} from './component/widgetTitleBar';
import {WidgetContainerComponent} from '../../../../../core/components/ui/widgetContainer.component';
import ShortcutComponent from "../shortcut/shortcut.component";

const ToolsComponent: FC<WidgetProps> = (props) => {
  return (
    <WidgetContainerComponent className={''}>
      <WidgetTitleBar widget={props.widget} hasTitle={props.hasTitle} />
      <RowComponent className="flex-wrap px-2 w-full">
        {props.widget.shortcuts?.map((item, index) => (
          <div key={index} className="w-1/4 md:w-auto p-2">
            <ShortcutComponent shortcut={item} />
          </div>
        ))}
      </RowComponent>
    </WidgetContainerComponent>
  );
};

export default React.memo(ToolsComponent);
