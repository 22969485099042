import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface BackIconProps {
  onClick: any;
  color?: string;
  isDisplay: boolean;
}

export const ArrowIconComponent: FC<BackIconProps> = (props) => {
  const { i18n } = useTranslation();
  return (
    <div className="w-auto pointer m-1" style={{ display: props.isDisplay ? 'block' : 'none' }} onClick={props.onClick}>
      {i18n.dir() == 'rtl' ? (
        <ArrowBackIcon style={{ color: props.color ?? 'var(--theme-page-text)', fontSize: '1.5rem' }} />
      ) : (
        <ArrowForwardIcon
          style={{ color: props.color ?? 'var(--theme-page-text)', fontSize: '1.5rem' }}
          fontSize={'small'}
        />
      )}
    </div>
  );
};
