import React, {FC} from 'react';
import {MenuBuilderShortcutModel} from '../../../../Main/main.model';
import {LOGIN_STATE_KEY, PERSIAN} from '../../../../../core/services/CONSTANTS';
import {useTranslation} from 'react-i18next';
import {onShortcutClick} from './service/shortcut.service';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../../../core/redux/store';

import {LOGIN_ACTION} from '../../../../profile/components/Login/redux/loginAction';
import {LOGIN_STATE} from '../../../../profile/components/Login/CONSTANTS';
import i18n from 'i18next';
import {ColumnComponent} from '../../../../../core/components/base/column.component';
import {useRouter} from 'next/router';
import {Avatar} from '@mui/material';
import useLocalStorageState from '../../../../../core/utils/useLocalStorage';

interface ShortcutProps {
  shortcut: MenuBuilderShortcutModel;
}

const CategoryShortcutComponent: FC<ShortcutProps> = (props) => {
  const { t } = useTranslation();
  const router = useRouter();

  let dispatch = useDispatch<AppDispatch>();
  const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);

  function getShortcutTitle() {
    if (props.shortcut.title) {
      return i18n.language == PERSIAN ? props.shortcut.title.fa : props.shortcut.title.en;
    } else {
      return t(`shName.${props.shortcut.unique_name}`);
    }
  }

  function handleShortcutClick() {
    if (props.shortcut?.is_enabled ?? true) {
      if ((props.shortcut.login_required ?? false) && !isLogin) {
        dispatch({
          type: LOGIN_ACTION,
          openLoginPage: true,
          state: LOGIN_STATE
        });
      } else {
        let title = getShortcutTitle();
        onShortcutClick(props.shortcut, title, router, dispatch);
      }
    }
  }

  function getIconColor(icon_color: string) {
    let color = icon_color.replace('#', '');
    let a = color.substr(0, 2);
    color = color.substring(2, 8) + a;
    return '#' + color;
  }

  return (
    <div className="w-full">
      {props.shortcut ? (
        <ColumnComponent
          className="pointer shortcutFrame relative"
          onClick={() => handleShortcutClick()}
          style={{ opacity: !(props.shortcut.is_enabled && !props.shortcut.is_enabled) ? '1' : '0.5' }}
        >
          {props.shortcut.cover_url ? (
            <Avatar
              variant="rounded"
              alt=""
              className="mRounded-2i shortcutCategoriesIconImage"
              style={{ backgroundColor: getIconColor(props.shortcut.icon_color) }}
              src={props.shortcut.cover_url}
            />
          ) : (
            <Avatar
              variant="rounded"
              alt=""
              className="mRounded-2i shortcutCategoriesIconImage"
              style={{ backgroundColor: getIconColor(props.shortcut.icon_color) }}
            >
              <div />
            </Avatar>
          )}
          {props.shortcut.title ? (
            <div className="text-center text-white text-base mt-2 p-1 shortcutCategoriesText">
              {i18n.language == PERSIAN ? props.shortcut.title.fa : props.shortcut.title.en}
            </div>
          ) : (
            <div className="text-center text-white text-base mt-2 p-1 shortcutCategoriesText">
              {t(`shName.${props.shortcut.unique_name}`)}
            </div>
          )}
        </ColumnComponent>
      ) : null}
    </div>
  );
};

export default React.memo(CategoryShortcutComponent)
