import { Observable } from 'rxjs';
import Api from '../../../../core/services/Api';
import { BOARD_FILM_URL, BOARD_VIDEO_URL, FILM_BOARD_URL, VIDEO_BOARD_URL } from '../CONSTANTS';
import { VideoBoardMainModel } from '../model/videoBoard.model';
import { VideoListMainModel } from '../model/video.model';
import { VideoDetailModel } from '../model/videoDetail.model';

export class VideoService {
  public getVideoBoard(isFilm: boolean, id: string): Observable<VideoBoardMainModel> {
    return Api.get<VideoBoardMainModel>((isFilm ? FILM_BOARD_URL : VIDEO_BOARD_URL) + id);
  }

  public getBoardVideo(isFilm: boolean, board: string, page: number): Observable<VideoListMainModel> {
    return Api.get<VideoListMainModel>(isFilm ? BOARD_FILM_URL : BOARD_VIDEO_URL, {
      board: board,
      page: page,
      page_size: 10
    });
  }

  public getVideoDetails(isFilm: boolean, token: string): Observable<VideoDetailModel> {
    return Api.get<VideoDetailModel>((isFilm ? BOARD_FILM_URL : BOARD_VIDEO_URL) + token);
  }

  public getVideoDetailsFromUrl(url: string): Observable<VideoDetailModel> {
    return Api.get<VideoDetailModel>(url);
  }

  public getVideoList(url: string): Observable<VideoListMainModel> {
    return Api.get<VideoListMainModel>(url);
  }
}
