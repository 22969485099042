import React, { CSSProperties, LegacyRef, PropsWithChildren } from 'react';

export interface ColumnProps {
  className?: string;
  dir?: string;
  style?: CSSProperties;
  onClick?: () => void;
  ref?: LegacyRef<HTMLDivElement>;
}

export function WidgetContainerComponent(props: PropsWithChildren<ColumnProps>): JSX.Element {
  return (
    <div
      dir={props.dir ?? 'locale'}
      ref={props.ref ?? undefined}
      className={`w-full flex flex-col lg:border-2 lg:mt-4 lg:border-gray-200 lg:rounded-md ${props.className}`}
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
