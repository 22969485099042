import React, {FC, useEffect, useState} from 'react';
import ShortcutComponent from '../../shortcut/shortcut.component';
import {RowComponent} from '../../../../../../core/components/base/row.component';
import {WidgetProps} from '../component/widgetTitleBar';
import {ColumnComponent} from '../../../../../../core/components/base/column.component';
import {FavoriteService} from './service/favorite.service';

import {LOGIN_STATE_KEY} from '../../../../../../core/services/CONSTANTS';
import {FavoriteDatabaseHelper} from './database/favorite.database.helper';
import {MenuBuilderShortcutModel} from '../../../../../Main/main.model';
import {ShortcutShowMoreComponent} from '../../shortcut/shortcutShowMore.component';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../../../../core/redux/store';
import useLocalStorageState from '../../../../../../core/utils/useLocalStorage';

export const FavoriteWidgetComponent: FC<WidgetProps> = (props) => {
  const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);
  const [favoriteItems, setFavoriteItems] = useState<MenuBuilderShortcutModel[]>([]);
  const [favoriteDefaultItems, setFavoriteDefaultItems] = useState<MenuBuilderShortcutModel[]>([]);
  let dispatch = useDispatch<AppDispatch>();
  const favoriteHelper = new FavoriteDatabaseHelper();
  useEffect(() => {
    if (isLogin) {
      const favoriteService = new FavoriteService();
      favoriteService.getFavorites().subscribe((data) => {
        if (data && data.data) {
          favoriteHelper.addFavoriteItems(data.data);
        }
      });
      favoriteHelper.getFavoriteItems().then((favorites) => {
        if (favorites && favorites.length > 0) {
          const favItems = favoriteHelper.favoriteMapper(favorites);
          if (favItems.length > 0) {
            setFavoriteDefaultItems(favItems);
          } else {
            setFavoriteDefaultItems(props.widget.shortcuts);
          }
          setFavoriteItems(favItems);
          favorites.forEach((fav) => {
            if (fav?.isDelete) {
              favoriteService.deleteFavoriteItem(fav.id).subscribe((data) => {
                favoriteHelper.deleteFavorite(fav.id);
              });
            } else if (!fav?.isSync) {
              favoriteService.addFavorite(fav.id).subscribe((data) => {
                favoriteHelper.updateFavoriteSync(fav.id, true);
              });
            }
          });
        }
      });
    }
  }, [isLogin]);

  return (
    <ColumnComponent className="responsiveWidth px-5 py-2">
      <RowComponent className="flex-wrap px-2 card card-box">
        {isLogin
          ? favoriteItems
              .filter((fav) => !(fav?.isDelete ?? false))
              .map((item, index) =>
                index < 7 ? (
                  <div key={index} className="w-1/4 md:w-1/6 p-2">
                    <ShortcutComponent shortcut={item} />
                  </div>
                ) : null
              )
          : favoriteDefaultItems?.map((item, index) =>
              index < 7 ? (
                <div key={index} className="w-1/4 md:w-1/6 p-2">
                  <ShortcutComponent shortcut={item} />
                </div>
              ) : null
            )}
        {favoriteItems.length > 0 || favoriteDefaultItems.length > 0 ? (
          <div className="w-1/4 md:w-1/6 p-2">
            <ShortcutShowMoreComponent />
          </div>
        ) : null}
      </RowComponent>
    </ColumnComponent>
  );
};
