import {Observable} from 'rxjs';
import {FavoriteMainModel} from '../model/favorite.model';
import Api from '../../../../../../../core/services/Api';
import {GET_FAVORITES_URL} from '../CONSTANTS';
import {getApiHeaderObjectWithToken} from '../../../../../../../core/services/config';
import {getAppPackage, getLoginAccessToken} from '../../../../../../../core/services/localStorage.service';
import i18n from 'i18next';

export class FavoriteService {
  public getFavorites(): Observable<FavoriteMainModel> {
    return Api.getWithHeader<FavoriteMainModel>(
      GET_FAVORITES_URL,
      getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken())
    );
  }

  public addFavorite(id: number): Observable<FavoriteMainModel | void> {
    return Api.postWithHeader<FavoriteMainModel>(
      GET_FAVORITES_URL,
      { id: id },
      getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken())
    );
  }

  public deleteFavoriteItem(id: number): Observable<FavoriteMainModel | void> {
    return Api.delete<FavoriteMainModel>(
      GET_FAVORITES_URL + id + '/',
      getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken())
    );
  }
}
