import React, {FC} from 'react';
import CategoryShortcutComponent from '../shortcut/categoryShortcut.component';
import {WidgetProps, WidgetTitleBar} from './component/widgetTitleBar';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {WidgetContainerComponent} from '../../../../../core/components/ui/widgetContainer.component';

const HorizontalCategoryWidgetComponent: FC<WidgetProps> = (props) => {
  return (
    <WidgetContainerComponent>
      <WidgetTitleBar widget={props.widget} hasTitle={props.hasTitle} />
      <RowComponent className="px-2 responsiveWidth">
        {props.widget.shortcuts?.map((item, index) =>
          index < (props.widget.schema_data.unit_max_size ?? 100) * 4 ? (
            <div key={index} className="w-1/2 md:w-1/3 p-2">
              <CategoryShortcutComponent shortcut={item} />
            </div>
          ) : null
        )}
      </RowComponent>
    </WidgetContainerComponent>
  );
};

export default React.memo(HorizontalCategoryWidgetComponent);
