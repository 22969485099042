import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {VideoService} from '../../../../tools/video/service/video.service';
import {VideoListModel} from '../../../../tools/video/model/video.model';
import VideoSwiperShortcutComponent from '../shortcut/videoSwiperShortcut.component';
import {FILM_SERIAL_PATH, VIDEO_GARDI_PATH} from '../../../../tools/video/CONSTANTS';
import {WidgetProps} from './component/widgetTitleBar';
import {ArrowIconComponent} from '../../../../../core/components/arrowIcon.component';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {useRouter} from 'next/router';
import {FreeMode} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Skeleton} from '@mui/material';
import {WidgetContainerComponent} from '../../../../../core/components/ui/widgetContainer.component';

const MediaSwiperWidgetComponent: FC<WidgetProps> = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const videoService = new VideoService();
  let [videoList, setVideoList] = useState<VideoListModel[]>([]);

  useEffect(() => {
    if (props.widget.schema_data.source_url) {
      videoService.getVideoList(props.widget.schema_data.source_url).subscribe((data) => {
        if (data && data.results.length > 0) {
          setVideoList(data.results);
        }
      });
    }
  }, []);

  function onClickAll() {
    let path: string = props.widget.show_more_page ?? '';
    if (props.widget.show_more_page?.includes('tlbx://media')) {
      if (path.includes('isFilm=false')) {
        path = VIDEO_GARDI_PATH;
      } else if (path.includes('isFilm=true')) {
        path = FILM_SERIAL_PATH;
      }
      router.push({
        pathname: path,
        search: `${props.widget.show_more_page?.split('tlbx://media')[1]}&shTitle=${props.widget.title}`
      });
    } else {
      router.push({
        pathname: props.widget.show_more_page ?? '',
        search: `shTitle=${props.widget.title}`
      });
    }
  }

  return (
    <WidgetContainerComponent className="fullWidth">
      <div>
        {props.hasTitle ? (
          props.widget.schema_data?.show_title == false ? null : (
            <RowComponent dir="auto" className="totalCenter px-2">
              <div className="text-base text-bold m-2 grow">{props.widget.title}</div>
              <div
                onClick={() => onClickAll()}
                style={{ display: props.widget?.schema_data?.show_more_enabled ? 'block' : 'none' }}
                className="text-base mTextBlue w-auto pointer"
              >
                {t('all')}
              </div>
              <ArrowIconComponent
                isDisplay={props.widget?.schema_data?.show_more_enabled ?? false}
                color={'var(--theme-color-blue)'}
                onClick={() => onClickAll()}
              />
            </RowComponent>
          )
        ) : null}
        {videoList?.length > 0 ? (
          <RowComponent className="flex-nowrap overflow-scroll hideScrollBar px-2">
            <Swiper slidesPerView={'auto'} freeMode={true} modules={[FreeMode]} className="mySwiper">
              {videoList?.map((item, index) => (
                <SwiperSlide style={{ width: 'auto' }} key={index} virtualIndex={index}>
                  <div className="w-auto p-1" key={index}>
                    <VideoSwiperShortcutComponent video={item} sourceUrl={props.widget.schema_data.source_url} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </RowComponent>
        ) : (
          <RowComponent className="flex-nowrap overflow-scroll hideScrollBar">
            {[...Array(5)].map((i, index) => (
              <div key={index} className="w-auto p-2 mb-20">
                <Skeleton className="card card-box rounded-1 videoBannerIconImage" variant="rectangular" />
              </div>
            ))}
          </RowComponent>
        )}
      </div>
    </WidgetContainerComponent>
  );
};

export default React.memo(MediaSwiperWidgetComponent);
